.body-piracy {
  margin: 0 auto;
  margin-top: 50px;
  width: 90%;
  max-width: 1024px;
  text-align: center;
}
.title {
  margin-bottom: 30px;
}

.piracy-content-container{
  margin-top: 2rem;
}

.div-video-piracy {
  width: 100%;
  height: 35rem;
  background-color: #ccc;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 720px) {
    height: 33vh;
  }
  margin-top: 5rem;
}

.button-continue {
  margin-top: 30px;
  background-color: #05f;
}

.hide-content {
  display: none;
}

.show-conten {
  display: block !important;
}

.list-not-to-do {
  max-width: 90%;
  margin: 0 auto;
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: left;
}
