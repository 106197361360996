.resources-qa-page{
  padding-bottom: 2rem;
  margin-top: -1rem;

  @media (max-width: 768px) {
    padding-top: 2rem;
  }

  .year-label {
    font-size: 1.2rem;
    padding: 1rem 0;
    font-weight: bold;
    text-align: center;
  }

  .year-option {
    text-align: center;
    padding: .5rem;
    margin-bottom: 0.5rem;
    cursor: pointer;

    &:hover {
      background-color: rgba(24, 130, 222, 0.8);
      color: white;
      font-weight: bold;
    }

    &.selected {
      background-color: #1882de;
      color: white;
      font-weight: bold;
    }
  }

  .recording-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem;
    margin: 0 4rem 1rem 0;
    background-color: rgba(0, 0, 0, 0.05);
    cursor: pointer;
    .name {
      flex: 1;
    }

    .play-icon {
      color: #1882de;
      font-size: 3rem;
      margin-right: 1rem;
    }
  }

  .main-card {
    padding: 1rem;

  }

  .recording-items{
    height: 100%
  }

  .sprout {
    position: relative;
    padding-bottom: 56.25%; /* 16:9, for an aspect ratio of 1:1 change to this value to 100% */
    width: calc(95vw - 340px);
    height: 0;

    .sproutvideo-player{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    $breakpoint-tablet: 900px;
    @media (max-width: $breakpoint-tablet) {
      width: 100%
    }
  }
}
