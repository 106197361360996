.glassCard {
  box-sizing: border-box;
  background: linear-gradient(
    123.54deg,
    rgba(27, 31, 39, 0.1) 0.7%,
    rgba(255, 255, 255, 0.01) 99.09%
  );
  border: 2px solid rgba(255, 255, 255, 0.08);
  box-shadow: 0px 10px 20px rgba(6, 8, 11, 0.25);
}

.glassCardNoShadow {
  box-sizing: border-box;
  background: linear-gradient(
    123.54deg,
    rgba(27, 31, 39, 0.1) 0.7%,
    rgba(255, 255, 255, 0.01) 99.09%
  );
  border: 2px solid rgba(255, 255, 255, 0.08);
}


.input {
  background: linear-gradient(
    123.54deg,
    rgba(27, 31, 39, 0.1) 0.7%,
    rgba(255, 255, 255, 0.01) 99.09%
  );
  border: 1px solid rgba(255, 255, 255, 0.08);
  border-radius: 12px;
}

.button {
  background: linear-gradient(#10141c, #10141c) padding-box,
    linear-gradient(to right, #0384fe, #03e5b4) border-box !important;
  /* GLASS STROKE */
  border: 2px solid transparent;
  border-radius: 16px;
}

.monkModeButton {
  background: linear-gradient(#1a1e26, #1a1e26) padding-box,
    linear-gradient(to right, #0384fe, #03e5b4) border-box !important;
  /* GLASS STROKE */
  border: 2px solid transparent;
  border-radius: 16px;
}

.button.disabled {
  background: transparent !important;
  border-color: rgba(255, 255, 255, 0.08);
  color: rgba(255, 255, 255, 0.08);
}
