.cards-container {
    display: flex;
    max-width: 900px;
    justify-content: space-evenly;
    z-index: 50;
    margin: 0 auto;


    .qa-card-container {
        text-align: center;
        margin: 0 0.5rem;
        border-radius: 15px;
        position: relative;
        background-size: cover;
        height: 550px;
        width: 350px;
        background-position: center center;
        transition: all 0.25s ease;

        .overlay {
            position: absolute;
            background-color: rgba(0,0,0,0.45);
            width: 100%;
            height: 100%;
            border-radius: 15px;

        }

        

        .img-container {
            display: flex;
            align-items: center;
            bottom: 20px;  
            position: absolute;
            width: 100%;   
 
        }
        
        img {
            margin: 0 auto;
            max-width: 200px;
            transition: 0.25s all ease;
        }

        .restriction-container {
            position: absolute;
            top: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            .restriction {
                background-color: rgba(0,0,0,0.75);
                color: #fff;
                text-transform: uppercase;
                padding: 0.1rem 0.5rem;
                display: inline-block;
                border-radius: 5px;
                opacity: 0;
                transition: 0.5s all ease;

                p {
                    margin-bottom: 0;
                    font-size: 0.75rem;
                    font-weight: 700;
                }
            }
            
        }

        .description {
            position: absolute;
            bottom: 0;
            color: #fff;
            font-size: 0.85rem;
            padding: 0 0.5rem;
            opacity: 0;
            transition: 0.5s all ease;
        }
     

        &:hover {
            transform: scale(1.02);
            cursor: pointer;
            box-shadow: 0px 0px 18px 5px #FFFFFF;

            .disabled {
                cursor: default;
            }

            img {
                opacity: 0;
            }
            
            .description {
                opacity: 1;
            }

            .restriction {
                opacity: 1;
            }

        }
    }




}
    
.hosts-button {
        color: #fff;
        font-weight: 700;
        font-size: 18px;
      
        padding: 0.75rem 1.5rem;
        border-radius: 50px;
        transition: 0.25s all ease;

        &:focus {
            outline: none;
        }

        &:hover {
            transform: scale(1.05);
        }
    }

.hosts-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 7rem;

    h1 {
        margin-bottom: 3rem;
    }

    
    .hosts-detail {
    // max-width: 900px;
    display: flex;
    

    .image-section {
        display: flex;
        // flex: 4;
        // background-color: green;
        border-right: 1px solid lightgray;

        img {
            border-radius: 15px;
            display: block;
            height: 100px;
            width: 100px;
            max-width: 100px;
            object-fit: cover;
        }
    }

    @media screen and (max-width: 640px) {
        .image-section {
            border-right: 0px;
        }
    }

    .description-section {
        display: flex;
        // flex: 5;
        // background-color: green;

        .content {
            h2 {
                font-size: 1.5rem;
            }

            h3 {
                // font-size: 1rem;
                // margin: 1.5rem 0rem;
            }
        }
    }
}
}

