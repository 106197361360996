.subsections {
    .subsection {
      border-bottom: 1px solid #eee;
      background: #fff;
    }
  
    .subsection-title {
      padding: 15px;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
  
      div {
        display: flex;
        flex-direction: row;
        span {
          font-size: 14px;
  
          &:first-child {
            font-weight: bold;
            color: #000;
          } 
        }
      }
    }

    .list-group-item {
        border:none;
    }
  }
  