.resend-email {
    cursor: pointer;
  
    padding: 10px; 
    text-align: center;
    font-weight: bold;
    color: #aaa;
    text-decoration: underline;
  
    &.enabled {
      color: #132e85;
    }
  }
  
  .remember-box {
    display: flex;
    margin-top: 1rem;
    label {
      color:white
    }
  }
  