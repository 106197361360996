.border-gradient {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  border-image: var(--tw-gradient-stops);
  border-image-slice: 1;
}

.glassCard {
  box-sizing: border-box;
  background: linear-gradient(
    123.54deg,
    rgba(27, 31, 39, 0.1) 0.7%,
    rgba(255, 255, 255, 0.01) 99.09%
  );
  border: 1px solid rgba(255, 255, 255, 0.08);
  /* box-shadow: 0px 10px 20px rgba(6, 8, 11, 0.25); */
  /* backdrop-filter: blur(10px); */
}

.glassCardBorderWhite {
  box-sizing: border-box;
  background: linear-gradient(
    123.54deg,
    rgba(27, 31, 39, 0.1) 0.7%,
    rgba(255, 255, 255, 0.01) 99.09%
  );
  border: 1px solid white;
  box-shadow: 0px 10px 20px rgba(6, 8, 11, 0.25);
  backdrop-filter: blur(10px);
}

.sidebar {
  background: linear-gradient(
    123.54deg,
    rgba(27, 31, 39, 0.1) 0.7%,
    rgba(255, 255, 255, 0.01) 99.09%
  );
  /* GLASS STROKE */
  border-right: 1px solid rgba(255, 255, 255, 0.08);
  box-shadow: 0px 10px 20px rgba(6, 8, 11, 0.35);
}

.scroll-container {
  will-change: transform;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
}
