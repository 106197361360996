.video-page {
  .loader {
    margin: 10vh 0;
  }


  .section-title {
    background: #1C1C1C;
    padding: 15px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;


    .video-count {
      font-size: 0.9rem;
      min-width: 6rem;
      text-align: right;
    }
  }

  .sidebar {

    padding-left: 0px;
    padding-right: 0px;
    // border-right: 1px solid #d7dadb;
    padding-bottom: 80px;

    overflow: scroll;
    display: inline-block;
    right: 0;
    .module-title {
      background: #e4e4e4;
      padding: 15px;
      h4 {
        font-size: 1rem;
        margin-bottom: 0px;
      }
    }
  }
  .main-content {
    // margin-top: 35px;
    // padding: 40px;
    // padding-top: 55px;
    // height: auto;
    h4 {
      margin-bottom: 15px;
    }
  }
  .sprout {
    position: relative;
    padding-bottom: 56.25%; /* 16:9, for an aspect ratio of 1:1 change to this value to 100% */


    .sproutvideo-player {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    $breakpoint-tablet: 900px;
    @media (max-width: $breakpoint-tablet) {
      width: 100%;
    }
  }
  .video-features {
    padding: 25px 0 0;
    // margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }
  .list-group {

    .list-group-item {
      cursor: pointer;
      background: transparent;
    }

    .active {
      // background-color: #1882de;

      .lesson-name {
        color: white;
        font-weight: bold;
      }
    }
    h6 {
      font-weight: 500;
      font-size: 0.9rem;
    }
  }

  .transcript-button {
    height: 55px;
    width: fit-content;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 0 10px;
    margin-left: 10px;
  }

  .transcript-label {
    font-size: 0.875rem;
    letter-spacing: 0.025em;
    color: #1d1d1d;
    font-weight: 600;
  }

  .mp3-player {

    height: auto;


    h5 {
      margin-left: 1rem;
    }

    .mp3-progress {
      width: 100%;
      height: 25px;
      padding: 1rem;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1rem;
    }

    .audioProgressBar {
      width: 80%;
      padding-left: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .audioProgressLabel {
      width: 20%;

      color: white;
      font-size: 14px;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    .mp3-buttons {
      display: flex;
      box-sizing: border-box;

      width: 100%;
      height: auto;
      padding: 1rem;
    }

    .mp3-play-pause {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      height: 45px;
      width: 45px;
      padding: 0px !important;

      img {
        height: 25px;
        width: 25px;
      }
    }
    .mp3-play-pause:hover {
      background-color:transparent !important;
    }
    .mp3-volume {
      display: flex;
      position: relative;
      margin-left: 1rem;

      height: 45px;
      width: 45px;
      transition: all 0.5s;
      overflow: hidden;
      background-color: transparent;
      border-radius: 10px;
      cursor: pointer;
    }

    .mp3-volume:hover {
      width: 11rem;
      background-color:transparent;
;
    }

    .mp3-volume-icon {
      height: 45px;
      width: 45px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 25px;
        width: 25px;
      }
    }

    .mp3-volume-slider {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 1rem;
      height: 45px;
      width: 7rem;
      padding-right: 1rem;
      position: absolute;
      margin-left: 60px;
    }
  }

  .transcript-box {
    box-shadow: 0px 0px 10px 3px #eee;
    width: 100%;
    height: auto;
    margin-top: 2rem;
    padding: 1rem;

    .transcript-block {
      display: flex;
      flex-direction: row;
    }

    .transcript-block-content {
      padding-left: 1rem;
      white-space: pre-line;

    }
  }
}
