.videoWrapper {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
}

.videoWrapper iframe,
.videoWrapper embed,
.videoWrapper object {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}
