@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: 'TildaSans';
	src: url('./assets/fonts/TildaSans-Black.eot');
	src:
	     url('./assets/fonts/TildaSans-Black.eot?#iefix') format('embedded-opentype'),
	     url('./assets/fonts/TildaSans-Black.woff2') format('woff2'),
	     url('./assets/fonts/TildaSans-Black.woff') format('woff');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'TildaSans';
	src: url('./assets/fonts/TildaSans-ExtraBold.eot');
	src:
	     url('./assets/fonts/TildaSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
	     url('./assets/fonts/TildaSans-ExtraBold.woff2') format('woff2'),
	     url('./assets/fonts/TildaSans-ExtraBold.woff') format('woff');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'TildaSans';
	src: url('./assets/fonts/TildaSans-Bold.eot');
	src:
	     url('./assets/fonts/TildaSans-Bold.eot?#iefix') format('embedded-opentype'),
	     url('./assets/fonts/TildaSans-Bold.woff2') format('woff2'),
	     url('./assets/fonts/TildaSans-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'TildaSans';
	src: url('./assets/fonts/TildaSans-Semibold.eot');
	src:
	     url('./assets/fonts/TildaSans-Semibold.eot?#iefix') format('embedded-opentype'),
	     url('./assets/fonts/TildaSans-Semibold.woff2') format('woff2'),
	     url('./assets/fonts/TildaSans-Semibold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'TildaSans';
	src: url('./assets/fonts/TildaSans-Medium.eot');
	src:
	     url('./assets/fonts/TildaSans-Medium.eot?#iefix') format('embedded-opentype'),
	     url('./assets/fonts/TildaSans-Medium.woff2') format('woff2'),
	     url('./assets/fonts/TildaSans-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'TildaSans';
	src: url('./assets/fonts/TildaSans-Regular.eot');
	src:
	     url('./assets/fonts/TildaSans-Regular.eot?#iefix') format('embedded-opentype'),
	     url('./assets/fonts/TildaSans-Regular.woff2') format('woff2'),
	     url('./assets/fonts/TildaSans-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'TildaSans';
	src: url('./assets/fonts/TildaSans-Light.eot');
	src:
	     url('./assets/fonts/TildaSans-Light.eot?#iefix') format('embedded-opentype'),
	     url('./assets/fonts/TildaSans-Light.woff2') format('woff2'),
	     url('./assets/fonts/TildaSans-Light.woff') format('woff');
	font-weight: 100 200 300;
	font-style: normal;
}

@layer base {
  html {
    font-family: 'TildaSans', Proxima Nova, system-ui, sans-serif !important;
  }
}

body {
  background: #0c1018;
}

.bodyBg {
  background: #0c1018;
}

@media (max-width: 767px) {
  .hosts-button {
    width: 250px;
  }
}


.body4smaller {
  font-size: 13px;
}

@media (max-width: 768px) {
  .body4smaller {
    font-size: 12px;
    line-height: 17px;
  }
}


.grey500 {
  color: #adadad;
}

.buttonLgDrk {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;

  position: absolute;
  width: 139px;
  height: 48px;
  left: 7px;
  top: 16px;

  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 150px;
}

.buttonMdDrk {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 22px;

  position: absolute;
  width: 124px;
  height: 40px;
  left: 7px;
  top: 84px;

  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 150px;
}

.buttonSmDrk {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 12px 4px 10px;

  position: absolute;
  width: 91px;
  height: 30px;
  left: 7px;
  top: 144px;

  background: #ffffff;
  /* White / 100% */

  border: 1px solid #ffffff;
  border-radius: 150px;
}

.buttonLgLight {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  color: #ffffff;
  width: 139px;
  height: 48px;
  border: 2px solid #ffffff;
  border-radius: 150px;
  /* font-family: Inter; */
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: center;
}

@media (max-width: 768px) {
  .buttonLgLight {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    color: #ffffff;
    width: 139px;
    height: 48px;
    border: 2px solid #ffffff;
    border-radius: 150px;
    /* font-family: Inter; */
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.20000000298023224px;
    text-align: center;
  }
}

.courseWidth {
  width: 315px;
}

.bounce-on-hover,
.opacity-on-hover {
  transition: all 0.5s;
}

.bounce-on-hover:hover {
  transform: translateY(-10px);
}

.opacity-on-hover:hover {
  opacity: 1;
}

@media (max-width: 768px) {
  .courseWidth {
    width: 200px;
  }
}

.buttonMdLight {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 22px;
  color: #ffffff;
  width: 124px;
  height: 40px;

  border: 2px solid #ffffff;
  border-radius: 150px;
  /* font-family: Inter; */
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.20000000298023224px;
  text-align: center;
}

.buttonSmLight {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  color: #ffffff;
  width: 96px;
  height: 30px;
  border: 2px solid #ffffff;
  border-radius: 150px;
  /* font-family: Inter; */
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.4000000059604645px;
  text-align: center;
}

.banner {
  background: linear-gradient(
      180deg,
      rgba(2, 4, 12, 0.2) 0%,
      rgba(2, 4, 12, 0.2) 100%
    ),
    url(./images/banner.svg);
  border-radius: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 767px) {
  .banner {
    background: linear-gradient(
        180deg,
        rgba(2, 4, 12, 0.2) 0%,
        rgba(2, 4, 12, 0.2) 100%
      ),
      url(./images/mobileBanner.png);
    border-radius: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
.heroBg {
  background: linear-gradient(
      180deg,
      rgba(2, 4, 12, 0.2) 0%,
      rgba(2, 4, 12, 0.2) 100%
    ),
    url(./images/phaseBg.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 767px) {
  .heroBg {
    background: linear-gradient(
        180deg,
        rgba(2, 4, 12, 0.2) 0%,
        rgba(2, 4, 12, 0.2) 100%
      ),
      url(./images/phaseBgMob.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.phaseMargin {
  margin-left: 8.33333%;
}

@media (max-width: 767px) {
  .phaseMargin {
    margin-left: auto;
    margin-right: auto;
  }
}

.clientTitle {
  font-size: 1.5rem;
  font-weight: 750;
  width: 15vw;
}

@media (max-width: 767px) {
  .clientTitle {
    font-size: 1.2rem;
    font-weight: 750;
    width: 25vw;
  }
}

.grey600Bg {
  background: #434343;
}

.grey700Bg {
  background: rgba(55, 55, 55, 1);
}

.grey800Bg {
  background: #1c1c1c;
}

.sidebarBg {
  background: #0c1018;
}

@media (max-width: 767px) {
  .sidebarBg {
    background: #1c1c1c;
  }
}

.clientBox {
  height: 60vh;
}
@media (max-width: 767px) {
  .clientBox {
    height: auto;
    max-height: 30vh;
  }
}

@media (max-width: 767px) {
  .video-page .mp3-player .mp3-progress {
    padding: 0.75rem !important;
  }
}

@media (max-width: 767px) {
  .video-page .mp3-player .mp3-buttons {
    padding-left: 0px !important;
  }
}

.inputBox {
  background-color: transparent;
  border-width: 1px;
  margin-bottom: 18px;
  border-color: transparent;
  --tw-ring-color: transparent !important;
}

.inputBox:focus {
  outline: none !important;
  box-shadow: none !important;
}
.outlineRemoved:focus {
  outline: none !important;
  box-shadow: none !important;
}

.grey600 {
  color: #434343;
}

.createButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;

  width: 330px;
  height: 48px;

  border: 2px solid #ffffff;
  border-radius: 150px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.mobile-only-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  padding: 1rem;
  font-weight: 600;
}

@media (min-width: 768px) {
  .mobile-only {
    display: none;
  }
}

@media (max-width: 767px) {
  .desktop-only {
    display: none;
  }
}

/*input:focus {
	outline: none; 
}*/

div.scrollmenu {
  background-color: transparent;
  overflow: auto;
  white-space: nowrap;
}

.linearGradient {
  background: linear-gradient(
    270deg,
    rgba(16, 16, 16, 0.7) 0%,
    rgba(16, 16, 16, 0) 100%
  );
}

.phaseBox {
  height: 229px;
  width: 279px;
  border-radius: 10px;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.57) 0%,
      #0047ff 52.08%,
      #00dbff 100%
    ),
    linear-gradient(0deg, #1c1c1c, #1c1c1c);

  /*	background: linear-gradient(180deg, rgba(0, 0, 0, 0.57) 0%, #0047FF 52.08%, #00DBFF 100%), #1C1C1C;
*/
}

@media (max-width: 767px) {
  .phaseBox {
    height: 229px;
    width: 100%;
    border-radius: 10px;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.57) 0%,
        #0047ff 52.08%,
        #00dbff 100%
      ),
      linear-gradient(0deg, #1c1c1c, #1c1c1c);

    /*	background: linear-gradient(180deg, rgba(0, 0, 0, 0.57) 0%, #0047FF 52.08%, #00DBFF 100%), #1C1C1C;
*/
  }
}

@media (max-width: 767px) {
  .video-page .main-content {
    margin-top: 20px;
    padding: 0px !important;
    height: auto;
  }
}

@media (max-width: 767px) {
  .video-page .video-features {
    padding: 25px 0 0;
    display: none !important;
    justify-content: space-between;
  }
}

@media (max-width: 767px) {
  .video-page .section-title {
    padding: 0px !important;
  }
}

.payModal {
  width: auto;
}

@media (max-width: 767px) {
  .payModal {
    width: 84vw;
  }
}

.payBx {
  width: 10vw;
}

@media (max-width: 767px) {
  .payBx {
    width: 100%;
  }
}

.progressBg {
  background: rgba(255, 255, 255, 0.5);
}

.progressBar {
  background: #ffffff;
}

.blkButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 2px solid #ffffff;
  border-radius: 150px;
}

.whiteBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 150px;
}

.whiteBtnNoFlex {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 150px;
}

/* Base for label styling */
[type='checkbox']:not(:checked),
[type='checkbox']:checked {
  position: absolute;
  left: 0;
  opacity: 0.01;
}
[type='checkbox']:not(:checked) + label,
[type='checkbox']:checked + label {
  position: relative;
  padding-left: 2.3em;
  /*		font-size: 1.05em;
*/
  line-height: 1.7;
  cursor: pointer;
}

/* checkbox aspect */
[type='checkbox']:not(:checked) + label:before,
[type='checkbox']:checked + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 1.6em;
  height: 1.6em;
  border: 1px solid #aaa;
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 50%;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 0 0 rgba(203, 34, 237, 0.2);
  -webkit-transition: all 0.275s;
  transition: all 0.275s;
}

/* checked mark aspect */
[type='checkbox']:not(:checked) + label:after,
[type='checkbox']:checked + label:after {
  content: '\2713';
  position: absolute;
  top: 0.725em;
  left: 0.38em;
  /*		font-size: 1.375em;*/
  color: black;
  line-height: 0;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

/* checked mark aspect changes */
[type='checkbox']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0) rotate(45deg);
  transform: scale(0) rotate(45deg);
}

[type='checkbox']:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1) rotate(0);
  transform: scale(1) rotate(0);
}

/* Accessibility */
[type='checkbox']:checked:focus + label:before,
[type='checkbox']:not(:checked):focus + label:before {
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1),
    0 0 0 6px rgba(203, 34, 237, 0.2);
}

/*
 * Useless styles, just for demo design
 */

/*form {
  width: 120px;
  margin: 0 auto 55px;
  text-align: left;
}*/

input[type='checkbox']:checked + label:before {
  background-color: white;
}

.grey700Border {
  border-bottom: 1px solid #373737;
}

.MuiTab-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0rem;
  text-align: center;
}

.MuiTab-root.MuiTab-textColorPrimary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 2px solid #ffffff;
  border-radius: 150px;
  color: white !important;
  padding: 0.3rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  /* font-family: Inter; */
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.20000000298023224px;
  text-align: center;
  min-height: auto;
  margin-right: 1rem;
}

.MuiTab-root.MuiTab-textColorPrimary.Mui-selected {
  color: black !important;
  background: white !important;
}

.MuiPaper-root {
  background-color: transparent !important;
}

.MuiGrid-item {
  color: white;
  /* font-family: Inter; */
}

.MuiPaper-elevation1 {
  color: white !important;
}

.MuiButtonBase-root.Mui-disabled {
  width: 0px;
}

.FinancialPlanner-tabs-1 {
  margin-top: 67px !important;
}

.MuiGrid-root {
  /* font-family: Inter; */
}

.MuiTab-root {
  text-transform: inherit !important;
}

.MuiMenu-list {
  color: white;
  background: rgba(55, 55, 55, 1);
}

.MuiSelect-icon {
  color: white !important;
}

.MuiSelect-select.MuiSelect-select {
  padding-right: 31px !important;
}

.react-responsive-modal-modal {
  background: #1c1c1c !important;
  color: white !important;
}

.css-yk16xz-control {
  background-color: transparent !important;
  border-color: transparent !important;
  border-bottom: 1px solid #434343 !important;
  color: white !important;
  outline: none !important;
  box-shadow: none !important;
}

.css-1pahdxg-control {
  background-color: transparent !important;
  border-color: transparent !important;
  border-bottom: 1px solid #434343 !important;
  outline: none !important;
  box-shadow: none !important;
}

.css-1uccc91-singleValue {
  color: white !important;
}

::-webkit-scrollbar .sidebar {
  width: 20px;
}

/* Track */
::-webkit-scrollbar-track .sidebar {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb .sidebar {
  background: red;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover .sidebar {
  background: #b30000;
}

.subsections .subsection-title div {
  display: flex;
  flex-direction: row;
}

.placeholderText::placeholder {
  color: #808080;
}

.but:focus {
  background: #434343 !important;
}

:root {
  --scrollbar-size: 0.375rem;
  --scrollbar-minlength: 1.5rem; /* Minimum length of scrollbar thumb (width of horizontal, height of vertical) */
  --scrollbar-ff-width: thin; /* FF-only accepts auto, thin, none */
  --scrollbar-track-color: transparent;
  --scrollbar-color: rgba(0, 0, 0, 0.2);
  --scrollbar-color-hover: rgba(0, 0, 0, 0.3);
  --scrollbar-color-active: rgb(0, 0, 0);
}

.layout-cell {
  overscroll-behavior: contain;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: var(--scrollbar-ff-width);
}

/* This class controls what elements have the new fancy scrollbar CSS */
.layout-scrollbar {
  scrollbar-color: var(--scrollbar-color) var(--scrollbar-track-color);
}
.layout-scrollbar::-webkit-scrollbar {
  height: var(--scrollbar-size);
  width: var(--scrollbar-size);
}
.layout-scrollbar::-webkit-scrollbar-track {
  background-color: var(--scrollbar-track-color);
}
.layout-scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-color);
  border-radius: 3px;
}
.layout-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-color-hover);
}
.layout-scrollbar::-webkit-scrollbar-thumb:active {
  background-color: var(--scrollbar-color-active);
}
.layout-scrollbar::-webkit-scrollbar-thumb:vertical {
  min-height: var(--scrollbar-minlength);
}
.layout-scrollbar::-webkit-scrollbar-thumb:horizontal {
  min-width: var(--scrollbar-minlength);
}
.layout-scrollbar::-webkit-scrollbar-corner {
  display: none;
}

/* 
.layout-scrollbar::-webkit-scrollbar-button {
  display: none;
} 
 */

.plug-block ul {
  list-style: disc !important;
  margin: auto !important;
  padding: revert !important;
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.plug-block p {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.plug-block a {
  color: #1882de !important;
  text-decoration: underline !important;
}

.plug-block .examples-block {
  background: #1c1c1c;
  border-left: 6px solid #373737;
  padding: 2rem;
}

.plug-block ol {
  list-style: auto !important;
  margin: auto !important;
  padding: revert !important;
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.plug-block ol li {
  margin-top: 1rem !important;
}

.plug-block img {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.card {
  word-wrap: break-word;

  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07) !important;
  border: 0.0625rem solid #151515;
  border-radius: 0.25rem;
}

.whiteTime {
  color: white !important;
}

.MuiInput-root {
  color: white !important;
}

.MuiIconButton-root {
  color: white !important;
}

.WithStyles\(ForwardRef\(InputBase\)\)-input-9 {
  border: none !important;
  padding: 8px 10px;
  position: relative;
  font-size: 14px !important;
  /* font-family: Inter !important; */
  font-weight: 600;
  line-height: 21px;
  background-color: transparent !important;
  color: white !important;
}

.MuiButtonBase-root.MuiMenuItem-root.Mui-disabled.menuSet {
  width: auto !important;
}

.footerBorder {
  border-top: 1px solid #373737;
}

.progressCard {
  width: 31rem;
}

@media (max-width: 767px) {
  .progressCard {
    width: 18rem;
  }
}

.result-title {
  text-align: center;
}

@media (max-width: 767px) {
  .simple-tools-page .right-input-col .card .result-grid-value {
    font-size: 1.5rem !important;
  }
}

.simple-tools-page .right-input-col .card .result-grid-label {
  padding: 0.25rem;
}

@media (max-width: 767px) {
  .MuiFormControl-root {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .MuiSelect-selectMenu {
    padding-left: 0px !important;
  }
}

@media (max-width: 767px) {
  h4 {
    font-size: 18px !important;
  }
}

@media (max-width: 767px) {
  .MuiGrid-grid-xs-6 {
    max-width: none !important;
  }
}

.whiteBg {
  background-color: white !important;
}

.titleText {
  font-size: 1.3rem;
  padding: 20px;
}

@media (max-width: 767px) {
  .titleText {
    font-size: 0.8rem;
    padding: 14px;
    padding-right: 10px;
    padding-left: 10px;
  }
}

.videoHeight {
  height: 31rem;
}

@media (max-width: 767px) {
  .videoHeight {
    height: 9rem;
  }
}

@media (max-width: 767px) {
  .simple-tools-page .audience {
    min-height: 620px !important;
  }
}

.MuiFormLabel-root {
  color: white !important;
}

.MuiInput-underline:before {
  border-bottom: 2px solid white !important;
}

.underline-36 {
  border-bottom: 2px solid white !important;
}

.underline-36:before {
  border-bottom: 2px solid white !important;
}

.MuiInput-underline:after {
  border-bottom: 2px solid white !important;
}

.underline-36:after {
  border-bottom: 2px solid white !important;
}

.MuiFormLabel-root-29 {
  color: white !important;
}

.buttonHover:hover {
  background-color: black !important;
}

.timeWidth {
  width: 7rem;
}

@media (max-width: 767px) {
  .timeWidth {
    width: 14.5rem;
  }
}

.objBx {
  margin-left: 0.5rem;
}

@media (max-width: 767px) {
  .objBx {
    width: 14.5rem;
    margin-right: auto;
    margin-left: 3rem;
  }
}
