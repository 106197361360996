.profile-main-avatar {
  display: flex;
  background-color: #ddd;
  position: relative;
  cursor: pointer;
  height: 9rem;
  width: 9rem;
  box-shadow: 1px 1px 5px 1px #777;
  border-radius: 4.5rem;
  overflow: hidden;
}

.camera-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  color: #fff;
  width: 9rem;
  height: 9rem;
  background: rgba($color: #000, $alpha: 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4.5rem;
  opacity: 0;
  transition: all 0.3s;

  &:hover {
    opacity: 1;
  }
}

.camera-icon-label {
  width: 4rem;
  text-align: center;
  font-size: 0.7rem;
  margin-top: 5px;
}
